<template>
  <div id="toTop" class="toTop difference" @click="toTop">
    <div class="arrow_holder">
      <svg
        class="arrowDown"
        alt="scrollDown_arrow"
        xmlns="http://www.w3.org/2000/svg"
        width="17.166"
        height="51.413"
        viewBox="0 0 17.166 51.413"
        style="fill: none; stroke-width: 2px"
      >
        <g transform="translate(16.459 29.307) rotate(90)">
          <path class="a" d="M0,0H50" transform="translate(-29.307 7.963)" />
          <path
            class="a"
            d="M0,0,7.876,7.876,0,15.752"
            transform="translate(12.816 0)"
          />
        </g>
      </svg>
      <svg
        class="arrowUp"
        xmlns="http://www.w3.org/2000/svg"
        width="17.166"
        height="22.107"
        viewBox="0 0 17.166 22.107"
        style="fill: none; stroke-width: 2px"
      >
        <g transform="translate(0.707 22.107) rotate(-90)">
          <path class="a" d="M0,0H19.434" transform="translate(0 7.789)" />
          <path
            class="a"
            d="M0,15.752,7.876,7.876,0,0"
            transform="translate(12.816 0)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted } from "@vue/runtime-core";
export default {
  name: "ToTop",
  setup() {
    const toTopScrolled = () => {
      const toTopEl = document.getElementById("toTop");
      if (window.scrollY > 50) {
        toTopEl.classList.add("scrolled");
      } else {
        toTopEl.classList.remove("scrolled");
      }
    };

    const toTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    onMounted(() => {
      window.addEventListener("scroll", toTopScrolled);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", toTopScrolled);
    });

    return { toTopScrolled, toTop };
  },
  //   mounted() {
  //     window.addEventListener("scroll", this.toTopScrolled);
  //     // const browser = navigator.userAgent.toLowerCase();
  //     // if (browser.indexOf("samsungbrowser") != -1) {
  //     //     document.querySelector('.toTop').classList.remove('difference');
  //     // }
  //   },
  //   unmounted() {
  //     window.removeEventListener("scroll", this.toTopScrolled);
  //   },
  // methods:{
  //     toTopScrolled(){
  //         const toTopEl = document.getElementById('toTop');
  //         if(window.scrollY > 50){
  //             toTopEl.classList.add('scrolled');
  //         } else{
  //             toTopEl.classList.remove('scrolled');
  //         }
  //     },
  //     toTop(){
  //         window.scrollTo({top : 0, behavior : 'smooth'});
  //     },
  // }
};
</script>

<style scoped>
/* toTop 시작 */
@keyframes moveDown {
  from {
    transform: translate(-51%, -100%);
  }
  to {
    transform: translate(-51%, 100%);
  }
}
.toTop {
  position: fixed;
  right: 4rem;
  bottom: 4rem;
  height: 8rem;
  width: 3.5rem;
  border: 2px solid #000;
  border-radius: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.6s;
  overflow: hidden;
  z-index: 10;
}
.toTop .arrow_holder {
  position: relative;
  overflow: hidden;
  width: 80%;
  height: 80%;
  user-select: none;
}
.toTop .arrow_holder svg {
  stroke: #000;
}
.toTop .arrow_holder .arrowDown {
  width: 1.5rem;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-51%);
  animation: moveDown 1.7s 0s infinite ease-in-out;
}
.toTop .arrow_holder .arrowUp {
  transition: transform 1s, opacity 1s;
  height: 0;
  width: 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
  opacity: 0;
}
.toTop .arrow_holder .arrowUp .line {
  transition: stroke 1s;
}
/* 스크롤시 START */
.toTop.scrolled {
  cursor: pointer;
  border-radius: 50%;
  height: 3.5rem;
  width: 3.5rem;
}
.toTop.scrolled .arrow_holder .arrowDown {
  height: 0;
  width: 0;
}
.toTop.scrolled .arrow_holder .arrowUp {
  height: 100%;
  width: 100%;
  transform: translate(-50%, 10%);
  opacity: 1;
}

/* difference */
.toTop.difference {
  mix-blend-mode: difference; /* 색 반전효과. body에 배경색 #fff를 넣지 않으면 안보일 수도 있음 */
  border: 2px solid #fff;
}
.toTop.difference .arrow_holder svg {
  stroke: #fff;
}
</style>
