<template>
  <div class="lottie"></div>
</template>

<script>
import lottie from "lottie-web";
import { onMounted } from "@vue/runtime-core";

export default {
  name: "Intro",
  setup() {
    const playLottie = () => {
      setTimeout(() => {
        lottie.loadAnimation({
          container: document.querySelector(".lottie"),
          renderer: "svg",
          loop: false,
          autoplay: true,
          animationData: require("@/assets/movie/title_motion_v5.json"),
        });
      }, 400);
      setTimeout(() => {
        const lottieEl = document.querySelector(".lottie");
        lottieEl.classList.add("off");
        setTimeout(() => {
          lottieEl.style.display = "none";
        }, 700);
      }, 2000);
      sessionStorage.setItem("introPlayed", true);
    };

    onMounted(() => {
      if (!sessionStorage.getItem("introPlayed")) {
        playLottie();
      } else {
        const lottieEl = document.querySelector(".lottie");
        lottieEl.style.display = "none";
      }
    });

    return { playLottie };
  },

  //   mounted() {
  //     if (!sessionStorage.getItem("introPlayed")) {
  //       this.playLottie();
  //     } else {
  //       const lottieEl = document.querySelector(".lottie");
  //       lottieEl.style.display = "none";
  //     }
  //   },
  //   methods: {
  //     playLottie() {
  //       setTimeout(() => {
  //         lottie.loadAnimation({
  //           container: document.querySelector(".lottie"),
  //           renderer: "svg",
  //           loop: false,
  //           autoplay: true,
  //           animationData: require("@/assets/movie/title_motion_v5.json"),
  //         });
  //       }, 400);
  //       setTimeout(() => {
  //         const lottieEl = document.querySelector(".lottie");
  //         lottieEl.classList.add("off");
  //         setTimeout(() => {
  //           lottieEl.style.display = "none";
  //         }, 700);
  //       }, 2000);
  //       sessionStorage.setItem("introPlayed", true);
  //     },
  //   },
};
</script>

<style scoped>
.lottie {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: #fff;
  transition: all 0.7s;
}
.lottie.off {
  opacity: 0;
}
</style>
