<template>
    <div class="c_content_container home">
        <!-- slogun START -->
        <div class="slogun">
            <div class="parallax">
                <h1 v-textMotion>
                    <span class="textMotion_target">
                        Keep <br /> <span>YOUNG</span> &amp; <span>CREATIVE</span>
                    </span>
                </h1>
            </div>
        </div>
        <!-- slogun END -->

        <!-- Movie START -->
        <section class="c_section movie">
            <div class="display">
                <video v-if="windowSize > 1100" autoplay="autoplay" muted="muted" loop="loop" playsinline
                    poster="/openGraph.jpg">
                    <source src="@/assets/movie/main_big.mp4" type="video/mp4" />
                    <img src="@/assets/movie/main_mobile.gif" alt="main_video" />
                </video>
                <video v-else autoplay muted loop playsinline>
                    <source src="@/assets/movie/main_small.mp4" type="video/mp4" />
                    <img src="@/assets/movie/main_mobile.gif" alt="main_video" />
                </video>
                <!-- <img v-else src="@/assets/movie/main_mobile.gif" alt="main_video"/> -->
            </div>
        </section>
        <!-- Movie END -->

        <!-- Overview START -->
        <section class="c_section overview">
            <div class="c_inner">
                <div class="textbox">
                    <div class="svg_wrap" v-fadein:reverse="{ time: 2 }">
                        <ImageD viewBox="0 0 479.94 479.94" />
                    </div>
                    <h2 v-textMotion class="text_large">
                        <span class="textMotion_target">
                            <span class="text_deco"> {{ $t("Contactless Era 01") }},</span><br />
                            {{ $t("Contactless Era 02") }}
                        </span>
                    </h2>
                    <br />
                    <p v-textMotion>
                        <span class="textMotion_target">
                            <span class="noPre">{{ $t("Contactless Era Txt noPre") }}</span>
                            <pre>{{ $t("Contactless Era Txt") }}</pre>
                            <span class="text_deco"> {{ $t("Contactless Era Txt Dc") }}</span>
                        </span>
                    </p>
                </div>
            </div>
        </section>
        <!-- Overview END -->

        <!-- Banner START -->
        <!-- !!!!!!!!!슬라이드로 만들기 -->
        <section class="c_section c_banner">
            <div class="c_inner">
                <Swiper v-bind="swiperFadeOption"
                    :effect="'fade'"
                >
                    <swiper-slide>
                        <div class="img_wrapper it-01">
                            <div class="icon_wrapper">
                                <img v-fadein:reverse src="@/assets/images/banner/icon_it.png" alt="아이콘" class="icon" />
                                <h2 v-fadein:reverse class="text_large">
                                    {{ $t("Home Banner 01") }}
                                </h2>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="img_wrapper it-02">
                            <div class="icon_wrapper">
                                <img v-fadein:reverse src="@/assets/images/banner/icon_it.png" alt="아이콘" class="icon" />
                                <h2 v-fadein:reverse class="text_large">
                                    IT 기술로 모든 것에 가치를 더합니다.
                                </h2>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="img_wrapper it-03">
                            <div class="icon_wrapper">
                                <img v-fadein:reverse src="@/assets/images/banner/icon_it.png" alt="아이콘" class="icon" />
                                <h2 v-fadein:reverse class="text_large">
                                    우리는 더 나은 디지털 경험을 만듭니다.
                                </h2>
                            </div>
                        </div>
                    </swiper-slide>
                </Swiper>
            </div>
        </section>
        <!-- Banner END -->

        <!-- Service START -->
        <section class="c_section service">
            <div class="c_inner">
                <ul>
                    <ServiceListBox which="web" title="Web Agency"
                        :image="require('@/assets/images/main/img_service_01.png')"
                        :keyword="['PC Website', 'Mobile Web', 'Responsive Web']" opposite="opposite">
                        <pre>{{ $t("Web Agency Txt") }}</pre>
                    </ServiceListBox>
                    <ServiceListBox which="app" title="App" :image="require('@/assets/images/main/img_service_02.png')"
                        :keyword="['Android', 'iOS', 'Native', 'Hybrid']">
                        <pre>{{ $t("App Txt") }}</pre>
                    </ServiceListBox>
                    <ServiceListBox which="commerce" title="Ecommerce"
                        :image="require('@/assets/images/main/img_service_03.png')"
                        :keyword="['Shoppingmall', 'SCM', 'CS ·Chat bot']" opposite="opposite">
                        <pre>{{ $t("Ecommerce Txt") }}</pre>
                    </ServiceListBox>
                    <ServiceListBox which="solution" title="Solution"
                        :image="require('@/assets/images/main/img_service_04.png')"
                        :keyword="['Monitoring', 'Customized', 'API']">
                        <pre>{{ $t("Solution Txt") }}</pre>
                    </ServiceListBox>
                </ul>
            </div>
        </section>
        <!-- Service END -->

        <!-- Vision-banner START -->
        <section class="c_section c_banner banner_vision">
            <div class="c_inner">
                <div class="img_wrapper vision">
                    <div class="icon_wrapper">
                        <img src="@/assets/images/banner/icon_vision.png" alt="아이콘" class="icon" />
                    </div>
                </div>
            </div>
            <div class="c_inner">
                <div class="page_text">
                    <h2 v-textMotion class="text_large">
                        <span class="textMotion_target">
                            <span class="text_deco">
                                {{ $t("Home Banner 021") }}
                            </span>
                            {{ $t("Home Banner 022") }}
                        </span>
                        <br/>
                        <span class="textMotion_target">
                            {{ $t("Home Banner 023") }}
                        </span>
                    </h2>
                </div>
            </div>
        </section>
        <!-- Vision-banner END -->

        <!-- Vision START -->
        <section class="c_section vision">
            <div class="c_inner">
                <swiper v-bind="swiperOption">
                    <swiper-slide class="slide_container">
                        <div class="slide_imgbox">
                            <div class="imgbox">
                                <img src="@/assets/images/main/img_vision_01.png" alt="태도" />
                            </div>
                        </div>
                        <div class="slide_bottom">
                            <div class="slide_text">
                                <h2 class="text_large">Our <strong class="attitude">Attitude</strong></h2>
                                <p>{{ $t("Our Attitude") }}</p>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide class="slide_container">
                        <div class="slide_imgbox">
                            <div class="imgbox">
                                <img src="@/assets/images/main/img_vision_02.png" alt="협력" />
                            </div>
                        </div>
                        <div class="slide_bottom">
                            <div class="slide_text">
                                <h2 class="text_large">Our <strong class="cooperation">Cooperation</strong></h2>
                                <p>{{ $t("Our Cooperation") }}</p>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide class="slide_container">
                        <div class="slide_imgbox">
                            <div class="imgbox">
                                <img src="@/assets/images/main/img_vision_04.png" alt="책임" />
                            </div>
                        </div>
                        <div class="slide_bottom">
                            <div class="slide_text">
                                <h2 class="text_large">Our <strong class="responsibility">Responsibility</strong></h2>
                                <p>{{ $t("Our Responsibility") }}</p>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide class="slide_container">
                        <div class="slide_imgbox">
                            <div class="imgbox">
                                <img src="@/assets/images/main/img_vision_03.png" alt="동기 부여" />
                            </div>
                        </div>
                        <div class="slide_bottom">
                            <div class="slide_text">
                                <h2 class="text_large">Our <strong class="motivation">Motivation</strong></h2>
                                <p>{{ $t("Our Motivation") }}</p>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </section>
        <!-- Vision END -->

        <!-- Contact-banner START -->
        <section class="c_section c_banner banner_contact">
            <div class="c_inner">
                <div class="img_wrapper contact">
                    <div class="icon_wrapper">
                        <img src="@/assets/images/banner/icon_contact.png" alt="아이콘" class="icon" />
                    </div>
                </div>
            </div>
            <div class="c_inner">
                <div class="page_text">
                    <h2 v-textMotion class="text_large">
                        <span class="textMotion_target">
                            <pre>{{ $t("Contact Us 01") }}</pre>
                        </span><br/>
                        <span class="textMotion_target">
                            <span class="text_deco">{{ $t("Contact Us 02") }}</span>
                            {{ $t("Contact Us 03") }}
                        </span>
                    </h2>
                </div>
            </div>
        </section>
        <!--Contact-banner END -->

        <!-- Contact START -->
        <section class="c_section contact_content">
            <div class="c_inner">
                <div class="l_company_info">
                    <dl v-textMotion class="text">
                        <dd class="textMotion_target">{{ $t("Response") }}</dd>
                    </dl>
                    <dl>
                        <dt>
                            <img src="@/assets/images/contact/icon_location.png" alt="아이콘" />
                        </dt>
                        <dd>{{ $t("Address") }}</dd>
                    </dl>
                    <dl>
                        <dt>
                            <img src="@/assets/images/contact/icon_email.png" alt="아이콘" />
                        </dt>
                        <dd>info@devercorp.com</dd>
                    </dl>
                    <dl>
                        <dt>
                            <img src="@/assets/images/contact/icon_tel.png" alt="아이콘" />
                        </dt>
                        <dd>02-3210-0144</dd>
                    </dl>
                </div>

                <div class="btn_wrapper">
                    <button @click="moveToContact" class="c_btn-common c_btn-primary btn_submit">
                        Contact Us!
                    </button>
                </div>
            </div>
        </section>
        <!-- 컨택트 END -->
    </div>
</template>

<script>
// SVG
import ImageD from "@/components/SVG/BackgroundD.vue";

// Components
import ServiceListBox from "@/components/Home/ServiceListBox";

// Swiper
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.min.css";

import SwiperCore, {
    EffectCreative,
    Pagination,
    Navigation,
    Autoplay,
    EffectFade
} from "swiper";
SwiperCore.use([EffectCreative, Pagination, Navigation, Autoplay, EffectFade]);

//router
import router from "../router";

import { onMounted, onUnmounted } from "@vue/runtime-core";

export default {
    name: "Home",
    components: {
        Swiper,
        SwiperSlide,
        ServiceListBox,
        ImageD,
    },
    setup() {
        let windowSize = window.innerWidth;
        const swiperOption = {
            effect: "creative",
            loop: true,
            autoplay: {
                delay: 3500,
                disableOnInteraction: false,
            },
            slidesPerView: 1.05,
            pagination: {
                type: "fraction",
            },
            navigation: true,
            creativeEffect: {
                prev: {
                    translate: ["-120%", 0, 0],
                    scale: 0.5,
                },
                next: {
                    translate: ["50%", "-20%", 0],
                    scale: 0.5,
                },
            },
        };
        const swiperFadeOption = {
            effect:"'fade'",
            loop:true,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
            effectFade:{
                prev:{
                    opacity:0,
                },
                next:{
                    opacity:0,
                }
            }
        };

        const parallaxSlogun = () => {
            const slogun = document.querySelector(".slogun");
            const parallax = document.querySelector(".parallax");

            if (window.scrollY * 0.4 < parallax.offsetHeight / 2) {
                slogun.style.opacity = 1;
                slogun.style.transform = "translateY(0)";
                parallax.style.transform = `translateY(${window.scrollY * 0.4}px)`;
            } else {
                slogun.style.opacity = 0;
                slogun.style.transform = "translateY(15%)";
            }
        };

        const videoScaleUp = () => {
            const movieEl = document.querySelector(".movie");
            const videoEl = document.querySelector(".movie .display video");

            const topHeight = movieEl.parentElement.offsetTop + movieEl.offsetTop; // 맨 위 위치
            const endHeight =
                movieEl.parentElement.offsetTop +
                movieEl.offsetTop +
                movieEl.offsetHeight -
                window.innerHeight; // 맨 끝 위치
            const totalHeight = endHeight - topHeight; // 총 길이
            let diff = window.scrollY - topHeight; // 현재 스크롤에서 내려온 거리

            if (0 <= diff && diff <= totalHeight / 2) {
                videoEl.style.transform = `scale(${1 + (0.3 / totalHeight) * diff})`;
            } else if (totalHeight / 2 < diff && diff <= totalHeight) {
                videoEl.style.transform = `scale(${1.3 - (0.3 / totalHeight) * diff})`;
            } else {
                videoEl.style.transform = "scale(1)";
            }
        };

        const getWindowSize = () => {
            windowSize = window.innerWidth;
        };

        const onToggleLanguage = () => {
            if (this.$i18n.locale === "en") return (this.$i18n.locale = "ko");
            this.$i18n.locale = "en";
        };

        const moveToContact = () => {
            router.push("/contact");
        };

        onMounted(() => {
            window.addEventListener("scroll", parallaxSlogun);
            window.addEventListener("scroll", videoScaleUp);
            window.addEventListener("resize", getWindowSize);
        });

        onUnmounted(() => {
            window.removeEventListener("scroll", parallaxSlogun);
            window.removeEventListener("scroll", videoScaleUp);
            window.removeEventListener("resize", getWindowSize);
        });

        return {
            windowSize,
            swiperOption,
            swiperFadeOption,
            parallaxSlogun,
            videoScaleUp,
            getWindowSize,
            onToggleLanguage,
            moveToContact,
        };
    },
};
</script>

<style scoped src="@/assets/css/main.css"></style>
