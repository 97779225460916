<template>
    <svg
        version="1.1"
        id="레이어_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        xml:space="preserve"
    >
        <slot />
    </svg>
</template>
