<template>
    <BaseSvg>
        <!-- BaseSvg 안에 있는 내용이 <slot> 내용으로 대체됨 -->
        <path class="st0"
            d="M683.2,262.4L683.2,262.4c-7.5,0-14.2-4.3-17.6-11L554.2,28.6c-4.9-9.7-0.9-21.5,8.8-26.4s21.5-0.9,26.4,8.8
                            l93.8,187.7L777.1,11c4.9-9.7,16.8-13.7,26.4-8.8s13.7,16.8,8.8,26.4L700.8,251.5C697.4,258.1,690.5,262.4,683.2,262.4" />
        <path class="st0" d="M508.8,39.5H305.7c-11,0-19.8-8.8-19.8-19.8S294.7,0,305.7,0h203.1c11,0,19.8,8.8,19.8,19.8
                            S519.6,39.5,508.8,39.5" />
        <path class="st0"
            d="M508.8,262.4H305.7c-11,0-19.8-8.8-19.8-19.8V131.2c0-11,8.8-19.8,19.8-19.8h203.1c11,0,19.8,8.8,19.8,19.8
                            s-8.8,19.8-19.8,19.8H325.2v72.2h183.6c11,0,19.8,8.8,19.8,19.8C528.6,253.8,519.6,262.4,508.8,262.4" />
        <path class="st0" d="M1060.7,39.5H857.6c-11,0-19.8-8.8-19.8-19.8S846.6,0,857.6,0h203.1c11,0,19.8,8.8,19.8,19.8
                            S1071.7,39.5,1060.7,39.5" />
        <path class="st0"
            d="M1060.7,262.4H857.6c-11,0-19.8-8.8-19.8-19.8V131.2c0-11,8.8-19.8,19.8-19.8h203.1c11,0,19.8,8.8,19.8,19.8
                            s-8.8,19.8-19.8,19.8H877.1v72.2h183.6c11,0,19.8,8.8,19.8,19.8C1080.5,253.8,1071.7,262.4,1060.7,262.4" />
        <path class="st0"
            d="M131.3,262.4H19.9c-11,0-19.8-8.8-19.8-19.8V19.8C0.1,8.8,8.9,0,19.9,0h111.5c72.4,0,131.2,58.8,131.2,131.2
                            C262.3,203.6,203.5,262.4,131.3,262.4 M39.4,223.1h91.7c50.7,0,91.7-41.2,91.7-91.7c0-50.7-41.2-91.7-91.7-91.7H39.4V223.1z" />
        <path class="st0" d="M1123.7,262.4c-11,0-19.8-8.8-19.8-19.8V19.8c0-11,8.8-19.8,19.8-19.8h167.1c41.7,0,75.4,33.7,75.4,75.4
                            s-33.7,75.4-75.4,75.4h-28.6l95.8,69.4c8.8,6.4,10.7,18.7,4.3,27.5c-6.4,8.8-18.7,10.7-27.5,4.3L1190,147.1
                            c-6.9-4.9-9.9-14-7.1-21.9c2.6-8.2,10.1-13.5,18.7-13.5h89.1c20,0,36.1-16.1,36.1-36.1s-16.1-36.1-36.1-36.1h-147.5v203.1
                            C1143.2,253.6,1134.6,262.4,1123.7,262.4" />
    </BaseSvg>
</template>

<script>
// import BaseSvg from "./BaseSvg.vue";
import BaseSvg from "./BaseSVG.vue";
export default {
    components: { BaseSvg },
};
</script>
