<template>
    <footer class="footer_container">
        <div class="footer">
            <ul class="f_menu">
                <li>
                    <router-link to="/work">{{ $t("Work") }}</router-link>
                    <ul class="submenu">
                        <li>
                            <router-link to="/work">{{ $t("Service Info") }}</router-link>
                        </li>
                        <li>
                            <router-link to="/priceInfo">{{ $t("Price Info") }}</router-link>
                        </li>
                    </ul>
                </li>
                <li>
                    <router-link to="/culture">{{ $t("Culture") }}</router-link>
                </li>
                <li>
                    <router-link to="/contact">{{ $t("Contact") }}</router-link>
                </li>
                <li>
                    <router-link to="/ci">{{ $t("About") }}</router-link>
                    <ul class="submenu">
                        <li>
                            <router-link to="/vision">{{ $t("Vision") }}</router-link>
                        </li>
                        <li>
                            <router-link to="/history">{{ $t("History") }}</router-link>
                        </li>
                        <li>
                            <router-link to="/clients">{{ $t("Clients") }}</router-link>
                        </li>
                    </ul>
                </li>
                <li>
                    <router-link to="/reference">{{ $t("Reference") }}</router-link>
                </li>
            </ul>
            <!-- <div class="footer_left">
                <ul class="menu">
                    <li>
                        <router-link to="/ci">회사소개</router-link>
                        <ul class="submenu">
                            <li>
                                <router-link to="/vision">비전</router-link>
                            </li>
                            <li>
                                <router-link to="/vision">연혁</router-link>
                            </li>
                            <li>
                                <router-link to="/vision"
                                    >클라이언트</router-link
                                >
                            </li>
                        </ul>
                    </li>
                    <li><router-link to="/work">작업</router-link></li>
                    <li><router-link to="/culture">문화</router-link></li>
                    <li><router-link to="/contact">연락</router-link></li>
                </ul>
                <div class="footer_info">
                    <div>
                        <span>데버 주식회사 대표이사 : 임종건</span>
                        <address>
                            서울시 중구 을지로 5길 19 페럼타워 23층
                        </address>
                        <span>info@devercorp.com</span>
                    </div>
                    <div>
                        <span>ⓒ DEVER corp. All rights reserved.</span>
                    </div>
                </div>
            </div> -->
            <div class="f_bottom">
                <div class="footer_info">
                    <div>
                        <span> {{ $t("Ceo") }}</span>
                        <address>
                            {{ $t("Address") }}
                        </address>
                        <span>info@devercorp.com</span>
                    </div>
                    <!-- <div>
                        <p>ⓒ DEVER corp. All rights reserved.</p>
                    </div> -->
                </div>
                <details class="f_select">
                    <summary>
                        <span>{{ $t("Related site") }}</span>
                        <div class="btn_wrap">
                            <div></div>
                            <div></div>
                        </div>
                        <!-- <div class="btn_wrapper">
                            <img
                                src="@/assets/images/common/icon_arrow_down_w.svg"
                            />
                        </div> -->
                    </summary>
                    <!-- <ul></ul> -->
                </details>
                <p>ⓒ DEVER corp. All rights reserved.</p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {};
</script>

<style scoped src="@/assets/css/components/footer.css"></style>
