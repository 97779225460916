<template>
  <!--header START-->
  <div class="header_container">
    <div class="header_bar difference" ref="header_bar">
      <router-link to="/" @click="onGnbToggle('remove')">
        <div class="svg_wrap">
          <Logo viewBox="0 0 1366 262.4" />
        </div>
      </router-link>
      <div class="btn_menu" @click="onGnbToggle">
        <div class="header_line"></div>
        <div class="header_line"></div>
        <div class="header_line"></div>
      </div>
    </div>
    <header ref="header">
      <div
        class="header_inner"
        v-bind:class="isDarkMode ? 'header_dark' : 'header_light'"
      >
        <ul class="menu_wrap">
          <!-- 업무 -->
          <li class="mainMenu mainMenuWork">
            <router-link
              data-key="Work"
              to=""
              @click="toggleActiveMenu($event)"
              :class="activeMenu === 'Work' ? 'active' : null"
            >
              {{ $t("Work") }}
            </router-link>
            <HeaderSubMenu
              :subMenuProps="workSubMenuProps"
              :isOpened="activeMenu === 'Work'"
              :onGnbToggle="onGnbToggle"
            />
          </li>
          <!-- 문화 -->
          <li class="mainMenu mainMenuCulture">
            <router-link
              data-key="Culture"
              to=""
              @click="toggleActiveMenu($event)"
              :class="activeMenu === 'Culture' ? 'active' : null"
            >
              {{ $t("Culture") }}
            </router-link>
            <HeaderSubMenu
              :subMenuProps="cultureSubMenuProps"
              :isOpened="activeMenu === 'Culture'"
              :onGnbToggle="onGnbToggle"
            />
          </li>
          <!-- 연락 -->
          <li class="mainMenu">
            <router-link
              to="/contact"
              data-key="Contact"
              @click="[onGnbToggle(), toggleActiveMenu($event)]"
              :class="activeMenu === 'Contact' ? 'active' : null"
            >
              {{ $t("Contact") }}
            </router-link>
          </li>
          <!-- 소개 -->
          <li class="mainMenu mainMenuAbout">
            <router-link
              data-key="About"
              to=""
              @click="toggleActiveMenu($event)"
              :class="activeMenu === 'About' ? 'active' : null"
              >{{ $t("About") }}</router-link
            >
            <HeaderSubMenu
              :subMenuProps="aboutSubMenuProps"
              :isOpened="activeMenu === 'About'"
              :onGnbToggle="onGnbToggle"
            />
          </li>
          <!-- 레퍼런스 -->
          <li class="mainMenu">
            <router-link
              to="/reference"
              data-key="Reference"
              @click="[onGnbToggle(), toggleActiveMenu($event)]"
              :class="activeMenu === 'Reference' ? 'active' : null"
            >
              {{ $t("Reference") }}
            </router-link>
          </li>
        </ul>

        <div class="mode_wrap">
          <div class="btn_mode mode_lang" @click="onToggleLanguage">
            <img
              v-if="locale === 'en'"
              src="@/assets/images/common/icon_korean.svg"
              alt=""
            />
            <!-- <span>{{ loacale }}</span> -->
            <img v-else src="@/assets/images/common/icon_english.svg" alt="" />
          </div>
          <div class="btn_mode mode_light" @click="onToggleDark">
            <img
              v-if="isDarkMode"
              src="@/assets/images/common/icon_light.svg"
              alt=""
            />
            <img v-else src="@/assets/images/common/icon_dark.svg" alt="" />
          </div>
        </div>
      </div>
    </header>
  </div>
  <!-- header END -->
</template>

<script>
import Logo from "../components/SVG/Logo.vue";
import HeaderSubMenu from "./HeaderSubMenu.vue";

import { onMounted, ref, watch, computed } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default {
  name: "Header",
  components: { Logo, HeaderSubMenu },
  setup() {
    const store = useStore();
    const route = useRoute();
    const header = ref(null);
    const header_bar = ref(null);
    const isOpenedWorkSubMenu = ref(true);
    const isOpenedAboutSubMenu = ref(false);
    const isOpenedCultureSubMenu = ref(false);
    const activeMenu = computed(() => {
      return store.state.activeMenu;
    });

    const workSubMenuProps = [
      { url: "/work", text: "Service Info" },
      { url: "/priceInfo", text: "Price Info" },
    ];
    const cultureSubMenuProps = [
      { url: "/culture", text: "Value" },
      { url: "/culture", text: "Story" },
    ];
    const aboutSubMenuProps = [
      { url: "/vision", text: "Vision" },
      { url: "/ci", text: "CI" },
      { url: "/history", text: "History" },
      { url: "/clients", text: "Clients" },
    ];

    const activeColor = "black";

    //I18n를 setup에서 사용하기 위함
    const { locale } = useI18n();

    const onToggleLanguage = () => {
      if (locale.value === "en") return (locale.value = "ko");
      locale.value = "en";
    };

    const onGnbToggle = (action = "toggle") => {
      const mainMenuAbout = document.querySelector(".mainMenuAbout");
      const mainMenuWork = document.querySelector(".mainMenuWork");
      const BodyEl = document.body;
      if (action == "remove") {
        header.value.classList.remove("on");
        header_bar.value.classList.remove("on");
        if (mainMenuAbout.classList.contains("show")) {
          mainMenuAbout.classList.remove("show");
        }
        if (mainMenuWork.classList.contains("show")) {
          mainMenuWork.classList.remove("show");
        }
        BodyEl.classList.remove("scroll_fixed");
      } else {
        header.value.classList.toggle("on");
        header_bar.value.classList.toggle("on");
        BodyEl.classList.toggle("scroll_fixed");
      }
    };

    const toggleActiveMenu = (e) => {
      store.commit("setActiveMenu", e.target.dataset.key);
    };

    onMounted(() => {
      const browser = navigator.userAgent.toLowerCase();
      if (browser.indexOf("samsungbrowser") != -1) {
        document.querySelector(".header_bar").classList.remove("difference");
      }
    });

    watch(
      () => route.name,
      () => {
        onGnbToggle("remove");
      }
    );

    return {
      workSubMenuProps,
      aboutSubMenuProps,
      cultureSubMenuProps,
      activeColor,
      locale,
      isOpenedWorkSubMenu,
      isOpenedAboutSubMenu,
      isOpenedCultureSubMenu,
      header,
      header_bar,
      activeMenu,
      toggleActiveMenu,
      ...useDarkMode(),
      onGnbToggle,
      onToggleLanguage,
    };
  },
};
const useDarkMode = () => {
  const store = useStore();
  const isDarkMode = ref(document.documentElement.dataset.theme === "dark");
  const onToggleDark = () => {
    // 다크모드 토글하면 <html> 태그에 붙어있는 data-theme="light | dark" 값이 변하고, common.css에 정의된 root 색상이 변함
    isDarkMode.value = !isDarkMode.value;
    if (isDarkMode.value) {
      document.documentElement.dataset.theme = "dark";
      store.commit("setLightMode", "dark");
      // console.log("다크모드");
    } else {
      document.documentElement.dataset.theme = "light";
      store.commit("setLightMode", "light");
      // console.log("라이트모드");
    }
  };
  return {
    isDarkMode,
    onToggleDark,
  };
};
</script>

<style scoped src="@/assets/css/components/header.css"></style>
