<template>
    <ul class="subMenu" v-if="isOpened">
        <li v-for="(item, i) in subMenuProps" :key="i">
            <router-link :to="item.url" @click="onGnbToggle">
                {{ $t(item.text) }}
            </router-link>
        </li>
    </ul>
</template>
<script>
export default {
    props: {
        subMenuProps: Array,
        isOpened: Boolean,
        onGnbToggle: Function,
    },
    setup() { },
};
</script>

<style scoped src="@/assets/css/components/header.css"></style>
