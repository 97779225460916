import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
// import LottieAnimation from "lottie-web";
// import LottieAnimation from "lottie-web-vue";
// import { VueReCaptcha } from 'vue-recaptcha-v3'

import { createI18n } from "vue-i18n";
import i18n_en from "@/assets/i18n/en.json";
import i18n_ko from "@/assets/i18n/ko.json";

import textMotion from "./directive/textMotion";
import fadein from "./directive/fadein";
import slidein from "./directive/slidein";
import circleMotion from "./directive/circleMotion";
import imageMotion from "./directive/imageMotion";

// axios 기본 url
axios.defaults.baseURL = "https://api.devercorp.com/api";

const app = createApp(App);

const messages = {
  ko: i18n_ko,
  en: i18n_en,
};

const i18n = createI18n({
  //vue3에서 사용하기 위해 legacy: false
  legacy: false,
  locale: "ko",
  messages,
});

app.directive("textMotion", textMotion);
app.directive("fadein", fadein);
app.directive("slidein", slidein);
app.directive("circleMotion", circleMotion);
app.directive("imageMotion", imageMotion);

// app.use(VueReCaptcha, {siteKey: '6Lca_LEeAAAAACAoctKFPEIDm7C5S507ltsEZa93'})

app.config.globalProperties.axios = axios;
app.use(store).use(router).use(i18n).mount("#app");
// app.use(store).use(router).use(i18n).use(LottieAnimation).mount('#app');
