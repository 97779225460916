import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  { // 홈
    path: '/',
    name: 'Home',
    component: Home
  },

  { // Work
    path: '/work',
    name: 'Work',
    component: () => import(/* webpackChunkName: "Work" */ '../views/Work/Work.vue')
  },
  { // Work
    path: '/priceInfo',
    name: 'priceInfo',
    component: () => import(/* webpackChunkName: "Work" */ '../views/Work/PriceInfo.vue')
  },

  { // Culture
    path: '/culture',
    name: 'Culture',
    component: () => import(/* webpackChunkName: "Culture" */ '../views/Culture/Culture.vue')
  },

  { // Contact
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "Contact" */ '../views/Contact/Contact.vue')
  },
  { // Contact - Detail
    path: '/contact/detail',
    name: 'ContactDetail',
    component: () => import(/* webpackChunkName: "ContactDetail" */ '../views/Contact/ContactDetail.vue'),
    props : route => ({ which: route.query.which ? route.query.which : '견적'})
  },
  { // Vision
    path: '/vision',
    name: 'Vision',
    component: () => import(/* webpackChunkName: "Vision" */ '../views/About/Vision.vue'),
  },
  { // CI
    path: '/ci',
    name: 'Ci',
    component: () => import(/* webpackChunkName: "Ci" */ '../views/About/Ci.vue'),
  },
  { // History
    path: '/history',
    name: 'History',
    component: () => import(/* webpackChunkName: "History" */ '../views/About/History.vue'),
  },
  { // Clients
    path: '/clients',
    name: 'Clients',
    component: () => import(/* webpackChunkName: "Clients" */ '../views/About/Clients.vue'),
  },
  { // ReferenceMain
    path: '/reference',
    name: 'Reference',
    component: () => import(/* webpackChunkName: "Reference" */ '../views/Reference/ReferenceMain.vue'),
  },
  { // Reference_Withfounder
    path: '/reference/withfounder',
    name: 'ReferenceWithfounder',
    component: () => import(/* webpackChunkName: "Reference" */ '../views/Reference/Reference_Withfounder.vue'),
  },
  { // Reference_Giveheart
    path: '/reference/giveheart',
    name: 'ReferenceGiveHeart',
    component: () => import(/* webpackChunkName: "Reference" */ '../views/Reference/Reference_GiveHeart.vue'),
  },
  { // Reference_NewFlex
    path: '/reference/newflex',
    name: 'ReferenceNewFlex',
    component: () => import(/* webpackChunkName: "Reference" */ '../views/Reference/Reference_NewFlex.vue'),
  },
  { // Reference_Vinacts
    path: '/reference/vinacts',
    name: 'ReferenceVinacts',
    component: () => import(/* webpackChunkName: "Reference" */ '../views/Reference/Reference_Vinacts.vue'),
  },
  { // Reference_TonPay
    path: '/reference/tonpay',
    name: 'ReferenceTonPay',
    component: () => import(/* webpackChunkName: "Reference" */ '../views/Reference/Reference_TonPay.vue'),
  },
  { // Reference_Iptk
    path: '/reference/iptk',
    name: 'ReferenceIptk',
    component: () => import(/* webpackChunkName: "Reference" */ '../views/Reference/Reference_Iptk.vue'),
  },
  { // Reference_HumanValue
    path: '/reference/humanvalue',
    name: 'ReferenceHumanValue',
    component: () => import(/* webpackChunkName: "Reference" */ '../views/Reference/Reference_HumanValue.vue'),
  },
  { // Reference_Eum
    path: '/reference/eum',
    name: 'ReferenceEum',
    component: () => import(/* webpackChunkName: "Reference" */ '../views/Reference/Reference_Eum.vue'),
  },
  { // Reference_Mpc
    path: '/reference/mpc',
    name: 'ReferenceMpc',
    component: () => import(/* webpackChunkName: "Reference" */ '../views/Reference/Reference_Mpc.vue'),
  },
  { // Reference_Papier
    path: '/reference/papier',
    name: 'ReferencePapier',
    component: () => import(/* webpackChunkName: "Reference" */ '../views/Reference/Reference_Papier.vue'),
  },
  { // Reference_ChezGabby
    path: '/reference/chezgabby',
    name: 'ReferenceChezGabby',
    component: () => import(/* webpackChunkName: "Reference" */ '../views/Reference/Reference_ChezGabby.vue'),
  },
  { // Reference_Hanvill
    path: '/reference/hanvill',
    name: 'ReferenceHanvill',
    component: () => import(/* webpackChunkName: "Reference" */ '../views/Reference/Reference_Hanvill.vue'),
  },
  { // Reference_NuovoSeoul
    path: '/reference/nuovoseoul',
    name: 'ReferenceNuovoSeoul',
    component: () => import(/* webpackChunkName: "Reference" */ '../views/Reference/Reference_NuovoSeoul.vue'),
  },
  { // Reference_TravelTo
    path: '/reference/travelto',
    name: 'ReferenceTravelTo',
    component: () => import(/* webpackChunkName: "Reference" */ '../views/Reference/Reference_TravelTo.vue'),
  },
  { // Reference_FreshMart
    path: '/reference/freshmart',
    name: 'ReferenceFreshMart',
    component: () => import(/* webpackChunkName: "Reference" */ '../views/Reference/Reference_FreshMart.vue'),
  },
  { // Reference_GrandCasa
    path: '/reference/grandcasa',
    name: 'ReferenceGrandCasa',
    component: () => import(/* webpackChunkName: "Reference" */ '../views/Reference/Reference_GrandCasa.vue'),
  },
  { // Reference_Sungwon
    path: '/reference/sungwon',
    name: 'ReferenceSungwon',
    component: () => import(/* webpackChunkName: "Reference" */ '../views/Reference/Reference_Sungwon.vue'),
  },
  { // Reference_Eieio
    path: '/reference/eieio',
    name: 'ReferenceEieio',
    component: () => import(/* webpackChunkName: "Reference" */ '../views/Reference/Reference_Eieio.vue'),
  },
  { // Reference_Urplace
    path: '/reference/urplace',
    name: 'ReferenceUrplace',
    component: () => import(/* webpackChunkName: "Reference" */ '../views/Reference/Reference_Urplace.vue'),
  },
  { // Reference_BellaClinic
    path: '/reference/bellaclinic',
    name: 'ReferenceBellaClinic',
    component: () => import(/* webpackChunkName: "Reference" */ '../views/Reference/Reference_BellaClinic.vue'),
  },

  { // 404 페이지
    path: "/404",
    name: "404",
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
  },
  { // 404로 리다이렉트
      path: '/:pathMatch(.*)*',
      redirect: "/404"
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      window.scrollTo({
        top: 0,
        // behavior: "smooth",
      });
    }
  },
})

export default router
